<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-card>
        <v-data-table
          dense
          :headers="headers"
          :items="desserts"
          :loading="loading"
          hide-default-footer
          :page.sync="page"
          :items-per-page="table.itemsPerPage"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Daftar Simpanan</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark class="mb-2" @click="dialog = !dialog"> Tambah </v-btn>
            </v-toolbar>
            <v-form class="ml-4">
              <v-row>
                <v-col cols="2" md="2">
                  <label for="firstname">Jenis Simpanan</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-select
                    :items="jenis_simpanans"
                    hide-details="auto"
                    outlined
                    dense
                    v-model="table.jenis_simpanan"
                    @change="optionChange()"
                  ></v-select>
                </v-col>
                <v-col cols="2" md="2">
                  <label for="firstname">Tanggal Mulai</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-text-field
                    v-model="table.tanggal_mulai"
                    hide-details="auto"
                    type="date"
                    dense
                    outlined
                    @change="optionChange()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" md="2">
                  <label for="firstname">Tipe</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-select
                    :items="jenis"
                    hide-details="auto"
                    outlined
                    dense
                    v-model="table.jenis"
                    @change="optionChange()"
                  ></v-select>
                </v-col>
                <v-col cols="2" md="2">
                  <label for="firstname">Tanggal Akhir</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-text-field
                    v-model="table.tanggal_akhir"
                    hide-details="auto"
                    type="date"
                    dense
                    outlined
                    @change="optionChange()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" md="2">
                  <label for="firstname">Jenis Upah</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-select
                    v-model="table.jenis_upah"
                    :items="jenis_upahs"
                    outlined
                    dense
                    hide-details="auto"
                    @change="optionChange()"
                  ></v-select>
                </v-col>
                <v-col cols="2" md="2">
                  <label for="firstname">Pencarian</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-text-field
                    v-model="table.search"
                    outlined
                    dense
                    placeholder="Nama/ Kode anggota"
                    @change="optionChange()"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" md="1">
                  <v-btn class="primary" small @click="optionChange()">Lihat</v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Tambah</span>
                </v-card-title>

                <v-card-text>
                  <v-form ref="form" lazy-validation>
                    <v-row>
                      <v-col cols="12" md="4">
                        <label for="firstnameHorizontalIcons">Anggota</label>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-btn color="primary" small @click="dialogPilihanggota = true"> Pilih Anggota </v-btn>
                        <input type="hidden" v-model="editedItem.id_anggota" />
                        <v-text-field readonly v-model="editedItem.kode_anggota" label="Kode Anggota *"></v-text-field>
                        <v-text-field readonly v-model="editedItem.nama_anggota" label="Nama Anggota *"></v-text-field>
                        <v-text-field
                          readonly
                          v-model="editedItem.jenis_upah_anggota"
                          label="Jenis Upah *"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <label for="firstnameHorizontalIcons">Jenis Simpanan</label>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-select
                          v-model="editedItem.id_jenis_simpanan"
                          :items="db_jenis_simpanans"
                          item-text="text"
                          item-value="value"
                          dense
                          outlined
                          hide-details="auto"
                          @change="jenisSimpananChange"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" md="4">
                        <label for="firstnameHorizontalIcons">Tgl Simpanan</label>
                      </v-col>
                      <v-col cols="12" md="8">
                        <!-- <input type="date" v-model="editedItem.tgl_simpanan" style="border: 1px solid grey" /> -->
                        <v-text-field v-model="editedItem.tgl_simpanan" type="date" dense outlined></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <label for="firstnameHorizontalIcons">Besar Simpanan</label>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-if="is_fixed == false"
                          v-model="editedItem.besar_simpanan"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                        <v-text-field
                          v-else
                          v-model="editedItem.besar_simpanan"
                          outlined
                          dense
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="close"> Batal </v-btn>
                  <v-btn color="primary" @click="save"> Simpan </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogPilihanggota" max-width="900px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Pilih Anggota</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <pilih-anggota></pilih-anggota>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="dialogPilihanggota = false"> Batal </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- dialog delete -->
            <v-dialog persistent v-model="dialogDelete" max-width="350">
              <v-card>
                <v-card-title class="headline">Konfirmasi</v-card-title>
                <v-card-text>
                  <h3 class="mb-4">Apakah yakin akan menghapus?</h3>
                  <v-text-field
                    v-model="pin_super"
                    type="password"
                    outlined
                    hide-details=""
                    dense
                    label="Masukkan pin:"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="closeDelete()">Batal</v-btn>
                  <v-btn color="error" @click="commitDeleteItem()">Hapus</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.anggota="{ item }">
            <td>
              <router-link :to="{ name: 'simpanan', params: { id_anggota: item.id_anggota } }">
                {{ item.nama_anggota + ' (' + item.kode_anggota + ')' }}
              </router-link>
            </td>
          </template>
          <template v-slot:item.besar_simpanan="{ item }">
            <div style="text-align: right">{{ new Intl.NumberFormat(['id']).format(item.besar_simpanan) }}</div>
          </template>
          <template v-slot:item.aksi="{ item }">
            <v-btn
              x-small
              v-if="item.jenis_simpanan != 'POKOK' && user.is_superadmin == true"
              @click="deleteItem(item)"
              color="error"
              title="Hapus"
            >
              Hapus
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" small @click="optionChange()"> Reset </v-btn>
          </template>
        </v-data-table>
        <div class="text-center px-4 py-4">
          <v-row>
            <v-col cols="3">
              <v-select
                label="Jumlah Per Halaman"
                :items="length_menus"
                hide-details="auto"
                outlined
                dense
                v-model="table.itemsPerPage"
                @change="optionChange()"
              ></v-select>
            </v-col>
            <v-col cols="9">
              <v-pagination v-model="page" :length="table.pageCount" :total-visible="7"></v-pagination>
            </v-col>
          </v-row>
        </div>

        <v-toolbar-title class="mt-4 ml-4">Informasi</v-toolbar-title>
        <v-simple-table class="mb-4" dense style="min-width: 300px">
          <tr>
            <td><b class="ml-4">Penjumlahan Besar Simpanan</b></td>
            <td>=</td>
            <th>{{ new Intl.NumberFormat(['id']).format(table.totalBesarSimpanan) }}</th>
          </tr>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiDelete } from '@mdi/js'
import PilihAnggota from '../pinjaman/pinjaman/PilihAnggota.vue'
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'

const apiRoot = process.env.VUE_APP_APIURL

export default {
  components: {
    PilihAnggota,
  },
  data() {
    return {
      icons: {
        mdiDelete,
      },
      headers: [
        { text: 'No', value: 'no', sortable: false },
        { text: 'Anggota', value: 'anggota', sortable: false },
        { text: 'Simpanan', value: 'jenis_simpanan', sortable: false },
        { text: 'Besar', value: 'besar_simpanan', sortable: false },
        { text: 'Tipe', value: 'jenis', sortable: false },
        { text: 'Jns Upah', value: 'jenis_upah', sortable: false },
        { text: 'Tanggal', value: 'tgl_simpanan', sortable: false },
        { text: 'User Entry', value: 'user_entry_name', sortable: false },
        { text: 'Aksi', value: 'aksi', sortable: false },
      ],
      desserts: [],
      loading: true,
      page: 1,
      table: {
        pageCount: 0,
        itemsPerPage: 25,
        search: '',
        jenis_simpanan: 'SEMUA',
        jenis: 'SEMUA',
        jenis_upah: 'SEMUA',
        tanggal_mulai:
          new Date()
            .toJSON()
            .slice(0, 8)
            .replace(/-/g, '-') + '01',
        tanggal_akhir: new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, '-'),
        totalBesarSimpanan: 0,
      },
      jenis_simpanans: ['SEMUA', 'POKOK', 'WAJIB'],
      jenis: ['SEMUA', 'DEBIT', 'KREDIT'],
      jenis_upahs: ['SEMUA', 'HARIAN', 'MINGGUAN', 'BULANAN'],
      length_menus: [25, 50, 100, 500, 1000],

      dialogDelete: false,
      pin_super: '',
      defaultItem: {
        id_jenis_simpanan: { text: '', value: '' },
        besar_simpanan: 0,
        tgl_simpanan: new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, '-'),
        id: '',
      },
      editedItem: {
        id_jenis_simpanan: { text: '', value: '' },
        besar_simpanan: 0,
        tgl_simpanan: new Date()
          .toJSON()
          .slice(0, 10)
          .replace(/-/g, '-'),
        id: '',
      },
      dialog: false,
      db_jenis_simpanans: [],
      is_fixed: false,

      dialogPilihanggota: false,
      editedIndex: -1,
    }
  },
  created() {
    // this.initialize()
    this.getDataFromApi()
  },
  computed: {
    token() {
      return this.$store.getters.token
    },
    anggotaSelected() {
      return this.$store.getters.anggotaSelected
    },
    user() {
      return this.$store.getters.user
    },
  },
  watch: {
    page: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    anggotaSelected() {
      // console.log()
      this.editedItem.id_anggota = this.anggotaSelected.id
      this.editedItem.kode_anggota = this.anggotaSelected.kode_anggota
      this.editedItem.nama_anggota = this.anggotaSelected.nama
      this.editedItem.jenis_upah_anggota = this.anggotaSelected.jenis_upah
      this.dialogPilihanggota = false
      this.getJenisSimpanans()
    },
  },
  methods: {
    // initialize() {},
    getDataFromApi() {
      this.loading = true

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('page', this.page)
      form.append('itemsPerPage', this.table.itemsPerPage)
      form.append('search', this.table.search)
      form.append('jenis_simpanan', this.table.jenis_simpanan)
      form.append('jenis', this.table.jenis)
      form.append('jenis_upah', this.table.jenis_upah)
      form.append('tanggal_mulai', this.table.tanggal_mulai)
      form.append('tanggal_akhir', this.table.tanggal_akhir)
      axios
        .post(`${apiRoot}/api/Simpanan/getSimpanans`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.table.pageCount = response.data.length

            this.table.totalBesarSimpanan = 0
            response.data.data.forEach(item => {
              this.table.totalBesarSimpanan += parseInt(item.besar_simpanan)
            })
          } else {
            alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
    optionChange() {
      if (this.table.tanggal_mulai != '' && this.table.tanggal_akhir != '') {
        this.page = 1
        this.getDataFromApi()
      }
    },

    deleteItem(item) {
      // this.editedItem = Object.assign({}, item)
      this.editedItem.id = item.id
      this.pin_super = ''
      this.dialogDelete = true
    },
    commitDeleteItem() {
      this.$store.state.loadingOverlay = true

      const fmData = new FormData()
      fmData.append('id_simpanan', this.editedItem.id)
      fmData.append('pin', this.pin_super)
      axiosPostAuth('api/Simpanan/hapusSimpanan', fmData)
        .then(response => {
          if (response.code === 200) {
            this.getDataFromApi()
            this.closeDelete()
          } else {
            alert(response.message)
          }
          this.$store.state.loadingOverlay = false
        })
        .catch(error => {
          alert(error)
          this.$store.state.loadingOverlay = false
        })
    },
    closeDelete() {
      this.editedItem.id = ''
      this.dialogDelete = false
    },
    close() {
      this.dialog = false
    },
    save() {
      this.$store.dispatch('loading', true)

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_jenis_simpanan', this.editedItem.id_jenis_simpanan)
      form.append('besar_simpanan', this.editedItem.besar_simpanan)
      form.append('id_anggota', this.editedItem.id_anggota)
      form.append('tgl_simpanan', this.editedItem.tgl_simpanan)
      axios
        .post(`${apiRoot}/api/Simpanan/add`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.getDataFromApi()
            this.dialog = false
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    getJenisSimpanans() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Simpanan/getJenis_byupah/${this.editedItem.jenis_upah_anggota}`, { headers })
        .then(response => {
          if (response.data.code === 200) {
            this.db_jenis_simpanans = []
            const arr = response.data.data
            arr.forEach(element => {
              this.db_jenis_simpanans.push({
                text: element.nama,
                value: element.id,
              })
            })
          } else {
            alert(response.data.message)
          }

          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    jenisSimpananChange() {
      this.getBesarSimpanan()
    },
    getBesarSimpanan() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Simpanan/getJenis/${this.editedItem.id_jenis_simpanan}`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            // console.log(response.data.data)
            if (response.data.data.is_fixed === '0') {
              this.is_fixed = false
            } else {
              this.is_fixed = true
            }
            this.editedItem.besar_simpanan = response.data.data.besar_simpanan
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
  },
}
</script>
